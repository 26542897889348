import { createMuiTheme } from "@material-ui/core/styles";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "rgb(30, 50, 85)",
    },
    secondary: {
      main: "rgb(4, 175, 136)",
    },
    ternary: {
      main: "rgb(51, 51, 51)",
    },
  },
});

export default theme;
